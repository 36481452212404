<template>
  <div
    class="remember-form"
    @keypress.enter="submit"
  >
    <h5>не переживайте, мы поможем восстановить доступ! 🔑</h5>
    <h6>
      укажите свою почту, чтобы&nbsp;восстановить пароль от&nbsp;аккаунта.
      новый&nbsp;пароль придет&nbsp;на&nbsp;почту
    </h6>

    <form-email-field
      ref="emailField"
      v-model="email"
      :submit-error="submitError"
      class="login-form__email"
      placeholder="почта"
      @clearSubmitError="clearSubmitError"
    />

    <button
      class="m-btn remember-form__login"
      :disabled="sending"
      @click="$emit('login')"
    >
      вернуться назад
    </button>

    <button
      type="submit"
      class="m-btn"
      :disabled="sending"
      @click="submit"
    >
      Восстановить
    </button>
  </div>
</template>

<script>
import FormEmailField from '@/components/v2/common/fileds/FormEmailField.vue';

export default {
  name: 'PurchaseRememberPasswordForm',
  components: { FormEmailField },
  data() {
    return {
      email: this.$store.getters.loginEmail,
      submitError: [],
      sending: false,
    };
  },
  methods: {
    clearSubmitError() {
      this.submitError = [];
    },
    async submit() {
      if (!this.$refs.emailField.validate()) {
        return;
      }

      this.sending = true;

      const response = await this.$api.remember(this.email);
      const data = response.data?.forgotPassword;

      if (data.success) {
        this.$store.commit('loginEmail', this.email);
        localStorage.setItem('REGISTER_PAGE', this.$route.name);
        localStorage.setItem('REGISTER_USER_EMAIL', this.email);
        this.$emit('success');
      } else {
        this.submitError = data.errors;
      }

      this.sending = false;
    },
  },
};
</script>

<style lang="scss" scoped>

.remember-form {
  display: flex;
  flex-direction: column;

  .login-form__email {
    margin-bottom: calc(var(--scale) * 40);
  }

  .remember-form__login {
    margin-bottom: calc(var(--scale) * 20);
    margin-inline: auto;

    font-weight: 400;
    font-size: calc(var(--scale) * 20);
    line-height: 130%;
    text-align: center;
    text-decoration-line: underline;
    color: #A7ABAC;

    @include media-down($size-tablet) {
      margin-bottom: calc(var(--scale) * 12);

      font-weight: 500;
      font-size: calc(var(--scale) * 14);
    }
  }
}

</style>
