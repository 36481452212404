<template>
  <div class="auth-form">
    <button
      id="closing_cross"
      class="auth-form__close m-btn"
      @click="closeAuthModal"
    >
      <img
        src="/v2/header/modal-close.svg"
        alt="Закрыть"
      >
    </button>

    <div
      :class="['auth-form-container', {
        error,
        'auth-form__remember-success': form === 'remember-success',
      }]"
    >
      <purchase-login-form
        v-if="form === 'login'"
        @login="login"
        @remember="form = 'remember'"
      />
      <purchase-remember-password-form
        v-if="form === 'remember'"
        @login="form = 'login'"
        @success="submitRemember"
      />
      <purchase-remember-success
        v-if="form === 'remember-success'"
        @login="form = 'login'"
      />
    </div>

    <div
      class="auth-form__overlay"
      @click="closeAuthModal"
    />
  </div>
</template>

<script>
import redirector from '@/service/redirector';
import PurchaseLoginForm from './PurchaseLoginForm.vue';
import PurchaseRememberPasswordForm from './PurchaseRememberPasswordForm.vue';
import PurchaseRememberSuccess from './PurchaseRememberSuccess.vue';

export default {
  name: 'PurchaseAuthModal',
  components: {
    PurchaseRememberSuccess,
    PurchaseLoginForm,
    PurchaseRememberPasswordForm,
  },

  data: () => ({
    form: 'login',
    error: false,
  }),

  mounted() {
    window.addEventListener('keydown', this.keyDownHnd);
    this.$store.dispatch('logout');
  },
  beforeMount() {
    window.removeEventListener('keydown', this.keyDownHnd);
  },

  methods: {
    closeAuthModal() {
      this.$emit('close');
    },
    login() {
      this.closeAuthModal();
      redirector.toCore();
    },
    submitRemember() {
      this.form = 'remember-success';
    },
    keyDownHnd(e) {
      if (e.key === 'Escape') {
        this.closeAuthModal();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.auth-form-container {
  padding: calc(var(--scale) * 32) calc(var(--scale) * 40) calc(var(--scale) * 40);
  width: calc(var(--scale) * 720);
  background-color: #FFFFFF;
  border-radius: calc(var(--scale) * 60);

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  box-sizing: border-box;
  transition: 200ms;
  cursor: default;

  @include media-down($size-tablet) {
    padding: calc(var(--scale) * 20) calc(var(--scale) * 16) calc(var(--scale) * 8);
    width: calc(var(--scale) * 366);
    border-radius: calc(var(--scale) * 32);
  }

  ::v-deep * {
    font-family: 'Onest', sans-serif;
  }
}

.auth-form {
  position: fixed;
  overflow-y: auto;
  cursor: pointer;

  animation-name: fadeIn;
  animation-duration: 0.4s;
  animation-fill-mode: both;

  inset: 0;
  z-index: 11;
  background-color: rgba(2, 18, 15, 0.3);
  backdrop-filter: blur(5px);

  &::-webkit-scrollbar {
    width: 0;
  }

  &__overlay {
    position: fixed;
    z-index: -1;
    inset: 0;
  }
}

.auth-form__close  {
  position: absolute;
  right: calc(var(--scale) * 40);
  top: calc(var(--scale) * 30);
  width: calc(var(--scale) * 42);
  height: calc(var(--scale) * 42);
  transition: transform ease 0.2s;

  @include media-down($size-tablet) {
    right: calc(var(--scale) * 8);
    top: calc(var(--scale) * 8);
  }

  img {
    width: calc(var(--scale) * 33);
    height: calc(var(--scale) * 33);
  }

  &:hover {
    transform: rotate(90deg);
  }
}

.auth-form__remember-success {
  background-image: url('/img/purchase-auth/success-bg.svg');
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: auto 90%;
}

::v-deep .fieldset {
  input {
    width: 100%;
    border-radius: 100px;
    background-color: transparent !important;
    height: calc(var(--scale) * 82);
    border: 1px solid #A7ABAC;

    font-weight: 500;
    font-size: calc(var(--scale) * 26);
    line-height: 110%;
    color: #02120F;

    @include media-down($size-tablet) {
      height: calc(var(--scale) * 68);

      font-size: calc(var(--scale) * 18);
      letter-spacing: -0.02em;
    }

    &::placeholder {
      color: #A7ABAC;
    }
  }
}

::v-deep button[type='submit'] {
  width: 100%;
  padding: calc(var(--scale) * 36);
  background-color: #FF5319;
  border-radius: calc(var(--scale) * 24);

  font-weight: 500;
  font-size: calc(var(--scale) * 32);
  line-height: 110%;
  color: #FFFFFF;

  @include media-down($size-tablet) {
    width: calc(100% + (var(--scale) * 16));
    margin-left: calc(-1 * var(--scale) * 8);
    padding: calc(var(--scale) * 30);

    font-size: calc(var(--scale) * 18);
  }
}

::v-deep h5 {
  margin-bottom: calc(var(--scale) * 12);

  font-weight: 500;
  font-size: calc(var(--scale) * 48);
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #02120F;

  @include media-down($size-tablet) {
    margin-bottom: calc(var(--scale) * 8);
    font-size: calc(var(--scale) * 28);
    line-height: 95%;
  }
}

::v-deep h6 {
  margin-bottom: calc(var(--scale) * 20);

  font-weight: 400;
  font-size: calc(var(--scale) * 20);
  line-height: 130%;
  color: #A7ABAC;

  @include media-down($size-tablet) {
    font-size: calc(var(--scale) * 16);
    letter-spacing: -0.02em;
  }
}
</style>
