<template>
  <div
    v-show="popup"
    class="new-year-popup"
  >
    <div
      class="new-year-popup__substrate"
      @click="popup = false"
    />

    <button
      class="m-btn new-year-popup__close"
      @click="popup = false"
    >
      <img
        src="/v2/header/modal-close.svg"
        alt="close"
      >
    </button>

    <div class="new-year-popup__content">
      <img
        src="/v2/new-year/2025/popup/bg.webp"
        alt="image"
        class="new-year-popup__content_image new-year-popup__content_image--desktop"
      >
      <img
        src="/v2/new-year/2025/popup/bg-tablet.webp"
        alt="image"
        class="new-year-popup__content_image new-year-popup__content_image--tablet"
      >
      <img
        src="/v2/new-year/2025/popup/bg-mob.webp"
        alt="image"
        class="new-year-popup__content_image new-year-popup__content_image--mobile"
      >
      <h3>
        начните год с&nbsp;финансовой <span><b>стабильности</b></span> вашей семьи
      </h3>
      <p>
        узнайте, как&nbsp;в&nbsp;новом году подготовить свою семью
        к&nbsp;экономическим вызовам 2025&nbsp;года и&nbsp;защитить будущее своих
        детей в&nbsp;условиях нестабильности
      </p>
      <span class="new-year-popup__content_cheap new-year-popup__content_cheap2">онлайн 24.12</span>
      <span class="new-year-popup__content_cheap new-year-popup__content_cheap1">вебинар</span>
      <button
        class="m-btn"
        @click="openModal"
      >
        зарегистрироваться
        <img src="/v2/new-year/2025/popup/tg-white.svg" alt="tg">
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { MODAL_TYPES } from '../../common/AppHeader/data';

export default {
  name: 'NewYearPopup',
  data: () => ({
    popup: false,
  }),
  mounted() {
    setTimeout(() => {
      this.popup = true;
    }, 9000);
  },
  methods: {
    ...mapActions('appHeaderModals', {
      openStoryModal: 'openModal',
    }),
    openModal() {
      this.openStoryModal(MODAL_TYPES.newYearPopup);
      this.popup = false;
    },
  },
};
</script>

<style scoped lang="scss">

.new-year-popup {
  position: fixed;
  z-index: 11;
  inset: 0;

  align-items: center;
  justify-content: center;
  display: flex;

  .new-year-popup__substrate {
    position: absolute;
    inset: 0;
    cursor: pointer;
    background-color: rgba(2, 18, 15, 0.3);
    backdrop-filter: blur(5px);
  }

  &__close {
    position: absolute;
    z-index: 2;
    right: calc(var(--scale) * 40);
    top: calc(var(--scale) * 30);
    width: calc(var(--scale) * 44);

    @include media-down($size-mobile) {
      right: calc(var(--scale) * 8);
      top: calc(var(--scale) * 8);
    }
  }

  .new-year-popup__content {
    max-height: 98dvh;
    width: calc(var(--scale) * 1220);
    padding: calc(var(--scale) * 32) calc(var(--scale) * 44) calc(var(--scale) * 40);

    position: relative;
    z-index: 2;
    border-radius: calc(var(--scale) * 60);

    overflow-y: auto;

    @include media-down($size-tablet) {
      width: calc(var(--scale) * 665);
      padding: calc(var(--scale) * 16) calc(var(--scale) * 20) calc(var(--scale) * 20);
      border-radius: calc(var(--scale) * 36);
    }

    @include media-down($size-mobile) {
      width: calc(var(--scale) * 366);
      padding: calc(var(--scale) * 20) calc(var(--scale) * 8) calc(var(--scale) * 8);
      border-radius: calc(var(--scale) * 20);
    }

    * {
      position: relative;
      z-index: 2;
      font-family: "Onest", sans-serif;
    }

    &_image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      &--tablet,
      &--mobile {
        display: none;
      }

      @include media-down($size-tablet) {
        &--desktop {
          display: none;
        }

        &--tablet {
          display: block;
        }
      }

      @include media-down($size-mobile) {
        &--tablet {
          display: none;
        }

        &--mobile {
          display: block;
        }
      }
    }

    h3 {
      max-width: calc(var(--scale) * 1046);
      margin-bottom: calc(var(--scale) * 16);

      font-weight: 500;
      font-size: calc(var(--scale) * 87);
      line-height: 90%;
      letter-spacing: -0.06em;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        max-width: calc(var(--scale) * 570);
        margin-bottom: calc(var(--scale) * 8);
        font-size: calc(var(--scale) * 44);
      }

      @include media-down($size-mobile) {
        padding-left: calc(var(--scale) * 8);
        margin-bottom: calc(var(--scale) * 5);
        max-width: calc(var(--scale) * 315);
      }

      span {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: calc(var(--scale) * 532);
          height: calc(var(--scale) * 87);
          left: calc(-1 * var(--scale) * 6);
          top: calc(var(--scale) * 16);

          background-color: #ED131C;

          @include media-down($size-tablet) {
            position: absolute;
            width: calc(var(--scale) * 275);
            height: calc(var(--scale) * 47);
            left: calc(-1 * var(--scale) * 5);
            top: calc(var(--scale) * 10);
          }
        }

        b {
          position: relative;
          z-index: 2;
        }
      }
    }

    p {
      max-width: calc(var(--scale) * 713);
      margin-bottom: calc(var(--scale) * 160);

      font-weight: 500;
      font-size: calc(var(--scale) * 28);
      line-height: 120%;
      color: rgba(255, 255, 255, 0.5);

      @include media-down($size-tablet) {
        max-width: calc(var(--scale) * 320);
        margin-bottom: calc(var(--scale) * 66);
        font-size: calc(var(--scale) * 15);
      }

      @include media-down($size-mobile) {
        padding-left: calc(var(--scale) * 8);
        margin-bottom: calc(var(--scale) * 135);
        max-width: calc(var(--scale) * 263);
        font-size: calc(var(--scale) * 18);
        color: rgba(255, 255, 255, 0.5);
      }
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: calc(var(--scale) * 14);

      width: calc(var(--scale) * 506);
      height: calc(var(--scale) * 122);

      border-radius: calc(var(--scale) * 40);
      background-color: #ED131C;

      font-weight: 500;
      font-size: calc(var(--scale) * 32);
      line-height: 110%;
      color: #FFFFFF;

      img {
        width: calc(var(--scale) * 44);
      }

      @include media-down($size-tablet) {
        gap: calc(var(--scale) * 9);
        width: calc(var(--scale) * 350);
        height: calc(var(--scale) * 80);
        border-radius: calc(var(--scale) * 24);
        font-size: calc(var(--scale) * 18);

        img {
          width: calc(var(--scale) * 32);
        }
      }
    }

    .new-year-popup__content_cheap {
      position: absolute;
      z-index: 2;

      display: flex;
      align-items: center;
      padding: calc(var(--scale) * 6) calc(var(--scale) * 14);
      gap: calc(var(--scale) * 1.5);
      border-radius: calc(var(--scale) * 60);

      font-weight: 500;
      font-size: calc(var(--scale) * 30);
      line-height: 130%;
      text-align: center;
      color: #FF5319;

      @include media-down($size-tablet) {
        padding: calc(var(--scale) * 4.5) calc(var(--scale) * 10.5);
        font-size: calc(var(--scale) * 22.5);
      }

      &1 {
        top: calc(var(--scale) * 210);
        right: calc(var(--scale) * 252);
        background-color: #FFFFFF;
        transform: rotate(4deg);

        @include media-down($size-tablet) {
          top: calc(var(--scale) * 112);
          right: calc(var(--scale) * 189);
        }

        @include media-down($size-mobile) {
          top: calc(var(--scale) * 332);
          right: calc(var(--scale) * 177);
        }
      }

      &2 {
        top: calc(var(--scale) * 262);
        right: calc(var(--scale) * 238);
        background-color: #FFF0D1;
        transform: rotate(-5deg);

        @include media-down($size-tablet) {
          top: calc(var(--scale) * 153);
          right: calc(var(--scale) * 178);
        }

        @include media-down($size-mobile) {
          top: calc(var(--scale) * 372);
          right: calc(var(--scale) * 167);
        }
      }
    }
  }
}

</style>
