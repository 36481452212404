<template>
  <div
    class="login-form"
    @keypress.enter="submit"
  >
    <h5>
      ого, похоже у вас уже есть&nbsp;доступ! 🔑
    </h5>
    <h6>
      войдите в&nbsp;свой аккаунт или&nbsp;восстановите пароль,
      чтобы&nbsp;получить&nbsp;доступ к&nbsp;платформе
    </h6>

    <form-email-field
      ref="emailField"
      v-model="email"
      :submit-error="submitError ? [{ invalidLogin: true }] : []"
      class="login-form__email"
      round-border
      placeholder="почта"
      @clearSubmitError="clearSubmitError"
    />

    <form-password-field
      ref="passwordField"
      v-model="password"
      :submit-error="submitError ? [{ invalidPassword: true }] : []"
      class="login-form__password"
      placeholder="пароль"
      @clearSubmitError="clearSubmitError"
    />

    <button
      id="link_to_renew_the_password"
      class="login-form__remember m-btn"
      @click="remember"
    >
      я забыл пароль и хочу его восстановить
    </button>

    <button
      id="button_sign_in"
      class="login-form__submit m-btn"
      type="submit"
      :disabled="sending"
      @click="submit"
    >
      Войти
    </button>
  </div>
</template>

<script>
import FormEmailField from '@/components/v2/common/fileds/FormEmailField.vue';
import FormPasswordField from '@/components/v2/common/fileds/FormPasswordField.vue';

export default {
  name: 'PurchaseLoginForm',
  components: { FormPasswordField, FormEmailField },

  data() {
    return {
      email: this.$store.getters.loginEmail,
      password: '',
      submitError: true,
      sending: false,
    };
  },

  methods: {
    clearSubmitError() {
      this.submitError = false;
    },

    validateForm() {
      let valid = true;

      if (!this.$refs.emailField.validate()) {
        valid = false;
      }

      if (!this.$refs.passwordField.validate()) {
        valid = false;
      }

      return valid;
    },

    async submit() {
      this.submitError = false;

      if (!this.validateForm()) {
        return;
      }

      this.sending = true;

      const user = await this.$api.login(this.email, this.password);

      if (!user.role) {
        this.submitError = true;
      } else {
        await this.$store.dispatch('login', user);

        localStorage.setItem('REGISTER_PAGE', this.$route.name);
        localStorage.setItem('REGISTER_USER_ID', user?.id);

        this.$emit('login', user);
      }

      this.sending = false;
    },

    remember() {
      if (this.email) {
        this.$store.commit('loginEmail', this.email);
      }

      this.$emit('remember');
    },
  },
};

</script>

<style scoped lang="scss">

.login-form {
  display: flex;
  flex-direction: column;

  .login-form__email {
    margin-bottom: calc(var(--scale) * 8);
  }

  .login-form__remember {
    margin: calc(var(--scale) * 38) auto calc(var(--scale) * 20);

    font-weight: 400;
    font-size: calc(var(--scale) * 20);
    line-height: 130%;
    text-align: center;
    text-decoration-line: underline;
    color: #FF9C12;

    @include media-down($size-tablet) {
      margin: calc(var(--scale) * 18) auto calc(var(--scale) * 12);

      font-weight: 500;
      font-size: calc(var(--scale) * 14);
    }
  }
}

</style>
